import React from "react";
import {ThemeProvider} from "styled-components";

const captainsCabinTheme = {
    app: {
        background: "#191D32",
        backgroundLight: "#353747",
        primaryFontFamily: "SignPainter",
        secondaryFontFamily: "",
        primary: "white",
        secondary: "rgba(255,255,255,0.7)",
        accent: "#15a7a7",
    },
    card: {
        background: "rgba(0,0,0,.3)",
        primary: "white",
        secondary: "rgba(255,255,255,0.7)",
        accent: "#15a7a7",
        gradient: "background: linear-gradient(90deg, rgba(32,74,107,1) 0%, rgba(25,29,50,1) 100%);"
    }

};

const oldLondonPubTheme = {
    app: {
        background: "#346363",
        backgroundLight: "#457f7f",
        primaryFontFamily: "OldStandardTT",
        secondaryFontFamily: "",
        primary: "white",
        secondary: "rgba(255,255,255,0.7)",
        accent: "#fcc63d",
    },
    card: {
        background: "rgba(0,0,0,.3)",
        primary: "white",
        secondary: "rgba(255,255,255,0.7)",
        accent: "#fcc63d",
        gradient: "background: linear-gradient(90deg, #41888a 0%, #1f5051 100%) padding-box;"
    }

};

interface Props {
    children: any;
}
const ApplicationTheme:React.FC<Props> = ({ children }) => (
    <ThemeProvider theme={process.env.REACT_APP_ID === 'oldlondonpub' ? oldLondonPubTheme : captainsCabinTheme}>{children}</ThemeProvider>
);

export default ApplicationTheme;
export { ApplicationTheme };
