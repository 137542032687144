import Category from "../models/Category";
import ReactGA4 from "react-ga4";

class AnalyticsStore {
    trackSelectedCategory(category: Category) {
        const categorySlug = category.title_en?.toLowerCase().split(" ").join("_");
        const pagePath = window.location.pathname + "category/" + categorySlug

        if (ReactGA4.isInitialized) {
            ReactGA4.send({ hitType: "pageview", page: pagePath });
        }
    }

    trackSearch(query: string) {
        const event = {
            category: 'Search',
            action: 'Search in menu',
            label: query.trim(),
        }

        if (ReactGA4.isInitialized) {
            ReactGA4.event(event);
        }
    }
}

export default AnalyticsStore;