interface IDictionary<TValue> {
    [id: string]: TValue;
}

const translation: IDictionary<string> = {
    "screen_search_results": "Rezultati",
    "screen_search_input_placeholder": "Vnesite ključne besede...",
    "screen_search_close": "Zapri",
    "screen_home_filter_by": "Filtriraj",
    "screen_home_best_selling": "Najbolje prodajano",
    "screen_home_my_table": "Moja miza",
    "screen_home_menu": "Meni",
    "screen_home_error": "Prišlo je do napake!",
    "screen_home_error_retry": "Poizkusite znova",
    "screen_table_total": "Znesek",
    "screen_misc_midnight_prices": "Izračun končne cene je zgolj informativnega značaja. Plačila ni možno izvesti preko aplikacije.",
    "screen_table_empty": "Vaša miza je prazna. Kliknite na željeni artikel, da bi ga dodali na mizo in si naredili informativni izračun končne cene."
};

export default translation;
