import React from 'react';
import Home from './screens/Home';
import Print from './screens/Print';
import PrintWithNightPrice from './screens/PrintWithNightPrice';
import styled from 'styled-components';
import ApplicationTheme from "./themes/theme";
import { Provider } from 'mobx-react';
import HomeStore from "./stores/Home";
import ApplicationStore from "./stores/Application";
import TranslationStore from "./stores/translation";
import AnalyticsStore from "./stores/Analytics";
import ReactGA4 from "react-ga4";

function App() {
  const home = new HomeStore();
  const application = new ApplicationStore();
  const translation = new TranslationStore(application);
  const analytics = new AnalyticsStore();

  if (process.env.REACT_APP_GOOGLE_ANALYTICS_4_TRACKING_CODE) {
    ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_4_TRACKING_CODE);
  }

  const stores = {
    home,
    application,
    translation,
    analytics,
  }

  const isPrint = window.location.pathname.includes("/pricing")

  if (isPrint) {
    const params = new URLSearchParams(window.location.search)
    const multiplierParam = params.get('multiplier');

    if (multiplierParam) {
      const multiplier = parseFloat(multiplierParam);

      return <Provider {...stores} >
        <ApplicationTheme>
          <PricingContainer>
            <PrintWithNightPrice nightPriceMultiplier={multiplier} />
          </PricingContainer>
        </ApplicationTheme>
      </Provider>
    }

    return <Provider {...stores} >
      <ApplicationTheme>
        <PricingContainer>
          <Print />
        </PricingContainer>
      </ApplicationTheme>
    </Provider>
  }

  return <Provider {...stores} >
    <ApplicationTheme>
      <Container hideOverflow={application.fixedScreenIsOpen}><Home /></Container>
    </ApplicationTheme>
  </Provider>;
}

const PricingContainer = styled.div``

const Container = styled.div<any>`
  background: ${p => p.theme.app.background};
  overflow: ${p => p.hideOverflow ? "hidden" : "visible"};
`;

export default App;
