import React from "react";
import styled from "styled-components";
import logo from "../assets/logo.svg";
import oldLondonPubLogo from "../assets/logo-oldlondonpub.png";
import bg from "../assets/header1.jpg";
import oldLondonPubHeader from "../assets/old-london-pub-header.jpg";
import SearchIcon from 'react-feather/dist/icons/search';
import {inject, observer} from "mobx-react";
import stores from "../stores";
import TranslationStore, {T_HOME_MENU} from "../stores/translation";

interface Props {
    onSearch: Function;
}

interface StoreProps extends Props {
    translation: TranslationStore;
}

@inject(stores.translation)
@observer
class Header extends React.Component<Props> {
    get store() {
        return this.props as StoreProps;
    }

    render() {
        let {onSearch} = this.props;
        return (
            <Container background={process.env.REACT_APP_ID === 'oldlondonpub' ? oldLondonPubHeader : bg}>
                <Row>
                    <Icon/>
                    <img 
                      alt="Old London Pub Logo" 
                      src={process.env.REACT_APP_ID === 'oldlondonpub' ? oldLondonPubLogo : logo} 
                      width={process.env.REACT_APP_ID === 'oldlondonpub' ? 400 : undefined} 
                    />
                    <Icon onClick={() => onSearch()}><SearchIcon/></Icon>
                </Row>
                {process.env.REACT_APP_ID !== 'oldlondonpub' && <Title>{this.store?.translation?.get(T_HOME_MENU)}</Title>}
                <Dim/>
            </Container>
        );
    }
}

export default Header;

const Row = styled.div`
    display: flex;
    color: ${p => p.theme.app.primary};
    z-index: 9;
    width: 100%;
    margin-top: -2rem;
`;

const Icon = styled.div`
  align-self: center;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  cursor: pointer;
`;

const Container = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${p => p.background});
  background-repeat: no-repeat no-repeat;
  background-size: cover;
  height: 260px;
  position: relative;
  padding: 0 2rem;
  
  img {
    z-index: 2;
    position: relative;
    max-width: 100%;
  }
`;

const Title = styled.div`
  color: ${p => p.theme.app.primary};
  text-transform: uppercase;
  font-size: 1.8rem;
  margin-top: 1rem;
  position: relative;
  z-index: 2;
  font-family: ${p => p.theme.app.primaryFontFamily};
`;

const Dim = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0,0.2);
  z-index: 0;
`;
