import APIService from "../services/api";
import { observable, toJS, computed, action } from "mobx";
import Category from "../models/Category";
import Item from "../models/Item";
import elasticlunr from "elasticlunr";
import { CategoriesTree } from "../types/categories";

interface CategoryState {
    data: Array<Category>;
    isLoading: boolean;
    isError: boolean;
}

interface ItemsState {
    data: Array<Item>;
    isLoading: boolean;
    isError: boolean;
}

interface IDictionary<TValue> {
    [id: string]: TValue;
}

class HomeStore {
    service: APIService = new APIService();
    index_sl = elasticlunr();
    index_en = elasticlunr();

    constructor() {
        this.index_en.addField("title_en");
        this.index_en.addField("subtitle_en");
        this.index_en.setRef("id");

        this.index_sl.addField("title_sl");
        this.index_sl.addField("subtitle_sl");
        this.index_sl.setRef("id");

        try {
            const table = localStorage.getItem("my_table") || JSON.stringify([]);
            const qty = localStorage.getItem("my_table_quantity") || JSON.stringify({});

            this.selectedProducts = JSON.parse(table);
            this.productsQuantity = JSON.parse(qty);

            this.selectedProducts.forEach((p) => {
                if (!this.productsQuantity[p]) {
                    this.productsQuantity[p.toString()] = 1;
                }
            });

            try {
                localStorage.setItem("my_table_quantity", JSON.stringify(toJS(this.productsQuantity)));
            } catch (e) { }
        } catch (e) { }
    }

    @observable
    categoriesState: CategoryState = {
        isLoading: false,
        isError: false,
        data: [],
    }

    @observable
    itemsState: ItemsState = {
        isLoading: false,
        isError: false,
        data: [],
    }

    @observable
    error?: any | null = null;

    @observable
    selectedProducts: Array<number> = [];

    @observable
    productsQuantity: IDictionary<number> = {};

    @observable
    selectedCategory?: number | null;

    @observable
    selectedFilter?: number | null = -1;

    async init(): Promise<any> {
        return this.fetchCategories();
    }

    @action
    setSelectedCategory(categoryId?: number | null) {
        this.selectedCategory = categoryId;
    }

    @action
    setSelectedFilter(categoryId?: number | null) {
        this.selectedFilter = categoryId;
    }

    @computed get topLevelCategories(): Array<Category> {
        if (this.categoriesState.data.length === 0) {
            return [];
        }

        return this.categoriesState.data.filter((i: Category) => !i.parent);
    }

    @computed get getSelectedCategorySubcategories(): Array<Category> {
        if (this.categoriesState.data.length === 0) {
            return [];
        }

        return this.categoriesState.data.filter((i: Category) => i.parent === this.selectedCategory)
    }

    getCategoriesTree = (categoryId?: number | null): CategoriesTree => {
        if (this.categoriesState.data.length === 0 || !categoryId) {
            return {
                category: null,
                children: [],
            };
        }

        const category = this.categoriesState.data.find(cat => cat.id === categoryId)!
        const immediateChildren = this.categoriesState.data.filter((i: Category) => i.parent === categoryId)
        
        const children: CategoriesTree[] = immediateChildren.map(subcategory => {
            const immediateChildren = this.categoriesState.data.filter((i: Category) => i.parent === subcategory.id)
            return {
                category: subcategory,
                children: [...immediateChildren.map(child => this.getCategoriesTree(child.id))]
            } as CategoriesTree
        })

        return {
            category,
            children,
        }
    }

    @computed get products() {
        return this.itemsState.data.filter((i: Item) => {
            return this.selectedProducts.indexOf(i.id!) !== -1;
        });
    }

    @computed get total() {
        const total = this.products?.reduce((prev, next) => {
            return prev + (this.productsQuantity[next.id!.toString()] * next.price!);
        }, 0);

        return total;
    }

    search = (query: string) => {
        const result_en = this.index_en.search(query, {
            expand: true,
            fields: {
                title_en: { boost: 2 },
                subtitle_en: { boost: 1 }
            }
        });

        const result_sl = this.index_sl.search(query, {
            expand: true,
            fields: {
                title_sl: { boost: 2 },
                subtitle_sl: { boost: 1 }
            }
        });

        const slo: Array<Item> = [];
        result_sl.forEach((r: any) => {
            const item = this.itemsState.data.find((i: Item) => i.id === parseInt(r.ref, 10));
            item && slo.push(item);
        });

        const en: Array<Item> = [];
        result_en.forEach((r: any) => {
            const item = this.itemsState.data.find((i: Item) => i.id === parseInt(r.ref, 10));
            item && en.push(item);
        });

        return {
            slo: slo,
            eng: en
        }
    };

    toggleProductSelection = (id: number) => {
        const found = this.selectedProducts.indexOf(id);

        if (found === -1) {
            this.selectedProducts.push(id);
            this.updateProductsQuantity(id, 1);
        } else {
            this.selectedProducts.splice(found, 1)
        }

        try {
            localStorage.setItem("my_table", JSON.stringify(toJS(this.selectedProducts)));
        } catch (e) { }
    }

    updateProductsQuantity = (id: number | string, qty: number) => {
        this.productsQuantity[id] = qty;

        try {
            localStorage.setItem("my_table_quantity", JSON.stringify(toJS(this.productsQuantity)));
        } catch (e) { }
    }

    isProductSelected = (id: number) => this.selectedProducts.indexOf(id) !== -1;

    async fetchCategories(): Promise<any> {
        this.categoriesState.isError = false;
        this.categoriesState.isLoading = true;

        try {
            // Fetch all categories, including subcategories
            const res = await this.service.categories().get();

            this.categoriesState.data = res.map((i: Category) => new Category().fromJSON(i));

            if (res.length > 0) {
                this.selectedCategory = res[0].id;
                await this.fetchItems();
            }

        } catch (e) {
            this.categoriesState.isError = true;
            this.error = e;
            console.log(e);
        }

        this.categoriesState.isLoading = false;
    }

    async fetchItems(): Promise<any> {
        this.categoriesState.isLoading = true;

        try {
            const res = await this.service.items().get();
            this.itemsState.data = res.map((i: Item) => new Item().fromJSON(i));

            this.itemsState.data.forEach(element => {

                this.index_en.addDoc({
                    id: element.id,
                    title_en: element.title_en,
                    subtitle_en: element.subtitle_en,
                });

                this.index_sl.addDoc({
                    id: element.id,
                    title_sl: element.title_sl,
                    subtitle_sl: element.subtitle_sl,
                });
            });

        } catch (e) {
            this.itemsState.isError = true;
            console.log(e);
        }

        this.itemsState.isLoading = false;
    }
}

export default HomeStore;
