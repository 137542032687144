import {observable} from "mobx";

enum Languages {
    english,
    slovenian
}

class ApplicationStore {
    @observable
    language: Languages = Languages.english;

    @observable
    fixedScreenIsOpen = false;

    constructor() {
        const storedLanguage = localStorage.getItem('language') || "en";
        
        if (storedLanguage === "en") {
            this.language = Languages.english;
        } else if (storedLanguage === "sl") {
            this.language = Languages.slovenian;
        }
    }

    changeLanguage = (language: Languages) => {
        this.language = language;
        localStorage.setItem('language', this.language === Languages.slovenian ? "sl" : "en");
    }
}

export { Languages };
export default ApplicationStore;
