import axios, { AxiosInstance } from "axios";

class APIService {
    client: AxiosInstance;

    constructor() {
        const baseURL = `${process.env.REACT_APP_API_URL}/pricing`

        this.client = axios.create({
            headers: {},
            baseURL,
        });
    }

    categories = () => ({
        get: async (query = {}) => {
            return (await this.client.get(`/categories/`, {
                params: query
            })).data;
        }
    });

    items = () => ({
        get: async (query = {}) => {
            return (await this.client.get(`/`, {
                params: query
            })).data;
        }
    });
}

export default APIService;
