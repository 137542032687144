interface IDictionary<TValue> {
    [id: string]: TValue;
}

const translation: IDictionary<string> = {
    "screen_search_results": "Results",
    "screen_search_input_placeholder": "Enter search keywords...",
    "screen_search_close": "Close",
    "screen_home_filter_by": "Filter by",
    "screen_home_best_selling": "Best selling",
    "screen_home_my_table": "My table",
    "screen_home_menu": "Menu",
    "screen_home_error": "Something went wrong!",
    "screen_home_error_retry": "Try again",
    "screen_table_total": "Total",
    "screen_misc_midnight_prices": "Total price calculation is for informational purposes only. You can not pay via mobile app.",
    "screen_table_empty": "Your table is empty. To calculate informational total price, please add some articles by tapping on them."
};

export default translation;
