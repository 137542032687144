import Category from "../models/Category"
import { CategoriesTree } from "../types/categories"

export function getFlatCategories(categoriesTree: CategoriesTree[]): Category[] {
    if (!categoriesTree.length || !categoriesTree[0].category) {
        return []
    }

    return categoriesTree.flatMap(node => {
        return [node.category!, ...getFlatCategories(node.children)]
    })
}