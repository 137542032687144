import React from "react";
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import stores from "../stores";
import ApplicationStore, {Languages} from "../stores/Application";

interface Props {
    onClick?: Function;
    title_en?: string;
    title_sl?: string;
    isSelected?: boolean;
}

interface StoreProps extends Props {
    application: ApplicationStore;
}

interface ContainerProps {
    onClick?: Function;
    isSelected?: boolean;
}

@inject(stores.application)
@observer
class CategoryItem extends React.Component<Props> {
    static defaultProps = {isSelected: false}

    get store() {
        return this.props as StoreProps;
    }

    render() {
        let {onClick, title_en, title_sl, isSelected} = this.props;

        let title = title_en;
        if (this.store.application.language === Languages.slovenian) {
            title = title_sl;
        }

        return (
            <Container onClick={() => onClick && !isSelected && onClick()} isSelected={isSelected}>
                <Title>{title}</Title>
            </Container>
        );
    }
}

export default CategoryItem;

const Container = styled.div<ContainerProps>`
  position: relative;
  cursor: ${p => !p.isSelected && `pointer`};
  display: inline-block;
  margin-bottom: 1rem;
  padding: 10px 0;
  margin-left: 1rem;
  margin-right: 1rem;
  user-select: none;
  transition: .4s all;
  font-family: ${p => p.theme.app.primaryFontFamily};
  font-size: 1.2rem;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &::after {
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%);
    width: 16px;
    height: 3px;
    border-radius: 8px;
    background-color: ${p => p.theme.app.accent};
    ${p => p.isSelected && `content: '';`};
  }
`;

const Title = styled.div`
  color: ${p => p.theme.app.primary};
`;
