import React from "react";
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import stores from "../stores";
import ApplicationStore, {Languages} from "../stores/Application";
import ModalImage from "react-modal-image";
import CloseIcon from "react-feather/dist/icons/x";
import QuantityPicker from "./QuantityPicker";
import HomeStore from "../stores/Home";

interface Props {
    onClick?: Function;
    onRemove?: Function;
    onQuantityChange?: Function;
    title_en?: string | null;
    title_sl?: string | null;
    subtitle_en?: string | null;
    subtitle_sl?: string | null;
    price?: string;
    isSelected?: boolean;
    image?: string | null;
    promoted?: boolean;
    id?: string | number;
    quantity?: number;
}

interface ContainerProps {
    onClick?: Function;
    isSelected?: boolean;
    hasImage?: boolean;
    promoted?: boolean;
}

interface StoreProps extends Props {
    application: ApplicationStore;
    home: HomeStore;
}

interface ColumnProps {
    fixed?: boolean;
    hasImage?: boolean;
    quantity?: boolean;
    full?: boolean;
}

@inject(stores.application, stores.home)
@observer
class SimpleCard extends React.Component<Props> {
    static defaultProps = {isSelected: false, promoted: false}

    get injected() {
        return this.props as StoreProps;
    }

    updateQuantity = (qty: number) => {
      this.props.onQuantityChange && this.props.onQuantityChange(qty);
    }

    render() {
        let {onClick, title_en, title_sl, subtitle_en, subtitle_sl, price, isSelected, image, promoted, onRemove} = this.props;

        let title = title_en;
        let subtitle = subtitle_en;

        if (this.injected.application.language === Languages.slovenian) {
            title = title_sl;
            subtitle = subtitle_sl;
        }

        let _price: number = parseFloat(price!);

        // const midnightPricePercentage = 0.01;
        // const midnightPrice =_price + _price * midnightPricePercentage;

        //console.log(price, midnightPrice, midnightPrice.toFixed(2));

        let displayQuantity = null;

        if (this.props.onQuantityChange) {
          displayQuantity = <Quantity>{this.props.quantity}×</Quantity>;
        }

        return (
            <Wrapper>
                {onRemove && <RemoveColumn onClick={() => onRemove && onRemove()}>
                    <CloseIcon />
                </RemoveColumn>}
                <Container onClick={() => onClick && onClick()} isSelected={isSelected} hasImage={!!image}
                           promoted={promoted}>

                    <Column full>
                        <Title>{title}</Title>
                        {subtitle && subtitle.length > 0 && <Subtitle>{subtitle}</Subtitle>}
                        {image && <Spacer height="1rem"/>}
                        {image && <Price>{_price.toFixed(2)} €</Price>}
                    </Column>
                    <Column fixed={!image} hasImage={!!image} quantity={!!this.props.onQuantityChange}>
                        {image && <ImageContainer>
                            <ModalImage
                                small={image}
                                large={image}
                                alt={title}
                                hideDownload={true}
                                imageBackgroundColor="transparent"
                            />
                        </ImageContainer>}
                        {!image && <Price>{displayQuantity} {_price.toFixed(2)} €</Price>}
                        <QtyControl>
                        {
                          this.props.onQuantityChange && <QuantityPicker quantity={this.props.quantity!} onChange={(qty: number) => {
                              this.updateQuantity(qty);
                          }} />
                        }
                        </QtyControl>
                    </Column>
                    
                </Container>
            </Wrapper>
        );
    }
}

export default SimpleCard;

const ImageContainer = styled.div`
    width: 110px;
    border-radius: 8px;
    overflow: hidden;
    height: 140px;
    position: relative;
    top: -30px;
    right: 0;
    margin-left: 1rem;
    
    & > div > img {
      width: 110px;
      height: 140px;
      object-fit: cover;
    }
`;

const Wrapper = styled.div`
  display: flex;
`;

const Spacer = styled.div<any>`
  width: 100%;
  height: ${p => p.height};
`;

const RemoveColumn = styled.div`
  width: 50px;
  height: 50px;
  color: red;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  padding-top: 10px;
  box-sizing: content-box;
`;

const Container = styled.div<ContainerProps>`
  border-radius: 8px;
  border: 2px solid transparent;
  ${ p => p.isSelected && `border-color: ${p.theme.card.accent}`};
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  background: ${p => p.theme.card.background};
  ${p => p.promoted && p.theme.card.gradient};
  padding: 10px 1rem;
  cursor: pointer;
  user-select: none;
  position: relative;
  ${p => p.hasImage && `margin-top: 2rem;`};
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
`;

const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  align-self: center;
  
  ${ p => p.fixed && `width: 120px; flex-basis: 120px;`};
  ${ p => p.fixed && `flex-shrink: 0`};
  ${ p => p.full && `flex: 1`};
  ${ p => p.fixed && `text-align: right`};
  ${ p => p.hasImage && `margin-bottom: -25px`};
  ${ p => p.quantity && `text-align: right;`};
`;

const Title = styled.div`
  color: ${p => p.theme.card.primary};
  font-weight: bold;
  max-width: 100%;
`;

const Subtitle = styled.div`
  color: ${p => p.theme.card.secondary};
  font-size: 0.8rem;
  max-width: 100%;
`;

const Price = styled.div<any>`
  //font-weight: bold;
  color: ${p => p.theme.card.primary};
`;

const QtyControl = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const Quantity = styled.span`
  font-weight: bold;
`;